import React from 'react'
import get from 'lodash/get'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import {
  JsonLdOrganization,
  JsonLdWebSite,
  SitePostSummary,
  SEOMetaTags,
} from 'blog-components'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'
// eslint-disable-next-line
import BlogPostDetail from '../fragments/BlogPostDetailFragment'

import * as messages from '../messages.json'
import './style.scss'

class Home extends React.Component {
  constructor(props) {
    super(props)

    let mostPopularSlugs = new Set(
      this.props.data.mostPopular.edges[0].node.slugs.map(
        element => element.slug
      )
    )

    let mostPopularPosts = this.props.data.allContentfulBlogPost.posts.filter(
      element => mostPopularSlugs.has(element.post.slug)
    )

    this.state = { mostPopularPosts: mostPopularPosts }
  }

  buildOtherFeatureListItems() {
    let featureList = []

    let features = messages.pages.home.components.otherFeatures.features

    for (let i = 0; i < features.length; i++) {
      featureList.push(
        <li key={i} className="list-group-item">
          {features[i]}
        </li>
      )
    }

    return featureList
  }

  render() {
    const pageLinks = []
    const mostPopularPostsComponents = []
    const site = get(this, 'props.data.site')
    const posts = get(this, 'props.data.allContentfulBlogPost.posts')
    const mostPopularPosts = get(this, 'state.mostPopularPosts')

    mostPopularPosts.forEach((element, i) => {
      mostPopularPostsComponents.push(
        <LazyLoad height={500} offset={500} once={true} key={i}>
          <div className="col-md-4 p-3">
            <SitePostSummary
              title={element.post.title}
              slug={element.post.slug}
              shareImage={element.post.shareImage.fluid}
              horizontal={true}
              key={i}
            />
          </div>
        </LazyLoad>
      )
    })

    posts.forEach((element, i) => {
      pageLinks.push(
        <LazyLoad height={500} offset={500} once={true} key={i}>
          <SitePostSummary
            title={element.post.title}
            slug={element.post.slug}
            authors={element.post.authors}
            datePublished={element.post.publishDate}
            dateLastModified={element.post.updatedAt}
            description={
              element.post.description.childMarkdownRemark.rawMarkdownBody
            }
            shareImage={element.post.shareImage.fluid}
          />
        </LazyLoad>
      )
    })

    let socialProfiles = [
      'https://www.twitter.com/PPennedOfficial',
      'https://www.facebook.com/PPennedOfficial',
      'https://www.instagram.com/PPennedOfficial',
      'https://www.pinterest.com/PPennedOfficial',
    ]

    let jsonLdEntries = [
      JsonLdOrganization.generate(
        site.siteMetadata.brand,
        site.siteMetadata.siteUrl,
        socialProfiles,
        site.siteMetadata.siteUrl + '/perfectly-penned-logo.png',
        '700',
        '700'
      ),
      JsonLdWebSite.generate(
        site.siteMetadata.brand,
        site.siteMetadata.siteUrl,
        site.siteMetadata.siteUrl + '/perfectly-penned-logo.png',
        socialProfiles,
        messages.pages.home.metaData.description
      ),
    ]

    return (
      <Layout>
        <SEOMetaTags
          path="/"
          site={site}
          title={messages.pages.home.metaData.title}
          description={messages.pages.home.metaData.description}
          imageUrl={site.siteMetadata.siteUrl}
          structuredData={jsonLdEntries}
        />

        <div className="container fadein-3" data-emergence="visible">
          {/* <div className="row justify-content-md-center text-center pt-5 px-3 no-gutters">
            <div className="col-md-10">
              <h2 className="display-3 py-5">
                {messages.pages.home.components.mostPopular.heading}
              </h2>
            </div>
          </div> */}
          <div className="row justify-content-md-center text-center no-gutters">
            {mostPopularPostsComponents}
          </div>
        </div>

        <div className="container fadein-3" data-emergence="visible">
          <div className="row justify-content-md-center text-center pt-5 px-3 no-gutters">
            <div className="col-md-10">
              <h2 className="display-3">
                {messages.pages.home.components.posts.heading}
              </h2>
            </div>
          </div>
          <div className="row justify-content-md-center text-center no-gutters">
            <div className="col-md-10 py-5">{pageLinks}</div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default Home
export const query = graphql`
  query HomePageQuery {
    site {
      ...SiteInformation
    }
    mostPopular: allMostPopularJson {
      edges {
        node {
          slugs {
            slug
          }
        }
      }
    }
    allContentfulBlogPost(sort: { order: DESC, fields: [publishDate] }) {
      posts: edges {
        post: node {
          ...BlogPostDetail
        }
      }
    }
  }
`
